@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');



:root {
    --primary-color: rgb(105, 173, 64); 
    --secondary-color: rgb(0, 42, 76);
}

html, body{
  overflow-x: hidden;
}

.header-section{
  background-image: url('/src/Assets/Images/header-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
}

.website-button{
  padding: 13px 35px;
  border-radius: 25px;
  background-color: var(--primary-color);
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 17px;
  border: none;
}

.website-button:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.py-8{
  padding:  220px 0;
}

.py-7{
  padding: 190px 0;
}

.py-9{
  padding: 150px 0;
}

.py-6{
  padding: 170px 0;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Montserrat', sans-serif;
}
p{
  font-family: 'montserrat', sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.site-heading{
  font-weight: 680;
  font-size: 300%;
  color: var(--secondary-color);
  line-height: 1.3;
}

.regular-heading{
  font-weight: 700;
  font-size: 200%;
  color: var(--secondary-color);
  line-height: 1.4;
}

.subtitle{
  color: var(--primary-color);
  background-color: #002a4c;
  padding: 4px 20px;
  border-radius: 25px;
  display: inline-block;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.site-logo{
  height: 50px;
}

/* Navbar styling */

.go-solar-nav{
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.navbar .nav-link{
  font-family: 'lato', sans-serif;
  font-weight: 600;
  color: var(--secondary-color) !important;
  font-size: 16px;
}

.nav-item{
  position: relative;
}

.nav-item::after {
  content: ""; 
  position: absolute;
  bottom: 0; 
  left: 10px; 
  width: 0;
  transform: translateX(-50%); 
  height: 2px; 
  background-color: var(--primary-color); 
}
.nav-item:hover::after {
  /* content: ""; 
  position: absolute; */
  /* bottom: 0; 
  left: 10px;  */
  width: 60%;
  transform: translateX(0);
  transition: transform 0.5s ease;
  /* height: 2px;  */
  /* background-color: var(--primary-color);  */
}

.card-icon{
  color: #fff;
  font-size: 50px;
}

.about-card{
  padding: 25px;
  border: none;
  background-color: var(--secondary-color);
  color: #fff;
}
.about-card:hover{
  background-color: var(--primary-color);
  transition: 0.6s ease;
}

.solar-services{
  background-image: url('/src/Assets/Images/services-bg-img.avif');
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  background-position: bottom center;
}
.services-overlay{
/* position: absolute;
top: 0;
left: 0; */
background-color: rgba(241, 245, 251, 0.92)
}
.service-card{
  padding: 15px;
  border-right: 6px solid #E0E0E0;
  border-bottom: 6px solid #E0E0E0;
  border-radius: 15px;
  height: 100%;
}
.service-card:hover{
  transform: translateY(20px);
  border-right: 6px solid var(--primary-color);
  border-bottom: 6px solid var(--primary-color);
  transition: 0.8s ease;
}
.service-card h5:hover{
  color: var(--primary-color);
}
.service-img{
  height: 150px;
  width: 100%;
}
.card-content a {
  text-decoration: none !important;
  color: #000;
}

.support {
  background: linear-gradient(to right, rgba(105, 173, 64, 40), rgba(0, 42, 76, 0.9)), url('/src/Assets/Images/support-team.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
}
.inner-page-header{
  background: linear-gradient(to right, rgba(105, 173, 64, 40), rgba(0, 42, 76, 0.7)), url('/src/Assets/Images/inner-page-header-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
}

.inner-page-header h2{
  color: #fff;
  font-weight: 550;
}


.stats{
  background-image: url('/src/Assets/Images/stats-bg-img.avif');
}

.stat-content:hover i{
  transform: rotateY(180deg);
  transition: transform 1s ease-in-out;
  color: var(--primary-color);
}

.testimonial-text p{
  color: #fff;
}
.about-images{
  position: relative;
 
}
.about-img-2{
  position: absolute;
  left: 30%;
  top: 50%;
  bottom: 0;
  height: 220px;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.4);
}
.about-img-1{
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.4);
  width: auto;
  height: 450px;
}

@media (max-width:600px){
  .about-img-2{
  position: absolute;
  left: 15%;
  top: 50%;
  bottom: 0;
  height: 220px;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.4);
}
}

.icon-box-2{
  margin-left: 25px;
}

.feature-icon-box .icon-tile{
  font-size: 90px;
   display: block;
   padding: 12px;
   border: 1px solid var(--primary-color);
   border-radius: 100px;
   margin-top: -60px;
   color: #fff;
   background-color: var(--secondary-color);
 }
 .icon-title:hover{
  background-color: var(--primary-color) !important;
 }
 .company-card{
  padding: 20px 15px;
  box-shadow: 0px 4px 8px 0px rgba(34,34,34,.52);
  text-align: center;
  height: 100%;
  border-radius: 15px;
 }
 .company-card:hover{
  background-color: var(--primary-color);
  color: #fff;
  transition: transform 2s ease;
 }
 .mission-vision{
  padding: 120px 0;
 }
 .mv-card:hover{
  transform: translateY(-10px);
  transition: transform 0.5s ease;
 }
 @media (max-width:600px){
  .mv-card{
    margin-bottom: 15%;
  }
  .h-about{
    margin-bottom: 8%;
  }
 }

 .accord-section .accordion-item {
  margin-bottom: 15px;
}

.about-col {
  background-image: url('/src/Assets/Images/about-us-img-2.avif');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
}

.overlay {
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(4, 38, 56, 0.9);
}

.empty-col {
  background-image: url('/src/Assets/Images/about-us-img-2.avif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.industrial-solar-services img{
  height: 380px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(34,34,34,.52);
}

.services-features img{
  height: 65px;
}

.feature-content{
  padding: 15px;
  border-radius: 15px;
}

.feature-content:hover{
  box-shadow: 0px 4px 8px 0px rgba(34,34,34,.52);
  border-radius: 15px;
  transform: translateY(-25px);
  transition: 0.6s ease;
}

.why-choose-industrial-solar img{
  height: 350px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(34,34,34,.52);
}

.call-to-action{
  background: linear-gradient(to right, rgba(105, 173, 64, 0.9), rgba(0, 42, 76, 0.7)), url('/src/Assets/Images/testimonials-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 30%;
}

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: var(--primary-color);
  font-weight: bold !important;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px){
  .styled-input {
      width:100%;
  }
}

.styled-input label {
  color: var(--primary-color);
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide { 
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  
  width: 100%;
  font-size: 1rem;
  background: #f1f3fb;
  color: #333333;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 10em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
            0 2px 10px 0 rgba(0,0,0,0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
            0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
  .submit-btn {
      width:100%;
      float: none;
      text-align:center;
  }
}

input[type=checkbox] + label {
color: #ccc;
font-style: italic;
} 

input[type=checkbox]:checked + label {
color: #f00;
font-style: normal;
}

.footer-services-list li{
  font-size: 17px;
  font-weight: 450;
  font-family: 'montserat', sans-serif;
}

.footer-services-list li i{
  font-size: 15px;
}

.footer-services-list li:hover{
  transform: translateX(5px);
  color: #FECE16;
  transition: 0.3s ease;
}

.test-container{
  background-image: url('/src/Assets/Images/clients-bg.avif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.test-overlay{
  background-color: rgba(105, 173, 64, 0.95);
}